.posts {
}

.posts-pagination {
  margin-top: 2em;
  text-align: center;

  .current {
    color: $secondary-color;
  }

  a {
    color: black;
    text-decoration: none;
    background-color: transparent;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

.posts-empty {
  text-align: center;
}

.post {
}

.post + .post {
  padding-top: 2em;
  margin-top: 2em;

  border-top: 1px dashed $secondary-color;
}

.post-heading {
  h1, h2 {
    margin-top: 0;
    margin-bottom: 0.8em;
  }

  h2 a {
    text-decoration: none;
    color: inherit;
  }
}

.post-content {
  word-wrap: break-word;
}

.post-info {
  margin-top: 1em;
  word-wrap: break-word;
}

.new-post {
  .info {
    margin: 0 38px;
  }

  .info-text {
    background-color: $success-color;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
}
