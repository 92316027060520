/**
  Styles for comment editor's content, we should merge it with comment editor theme styles
 */
.lexical.comment-content {
  // from inline styles on .editor-content (CommentEditor's contenteditable element)
  * {
    white-space: pre-wrap;
    word-break: break-word;
  }

  // from comment editor theme on .editor-paragraph (CommentEditor/index.scss)
  p {
    margin: 0;
  }

  // Styles for embeds
  .embed-container {
    .embed-wrapper {
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 56.25%;

      iframe {
        padding: 0;
        margin: 0;
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        vertical-align: middle;
      }
    }
  }

  // Styles for images
  .image-container {
    .image-wrapper {
      img {
        max-width: 100%;
      }
    }
  }
}