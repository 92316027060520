.animate-comment-counter-in {
  display: inline-block;
  color: $brand-color;
  animation: fade-in 0.25s ease-in,
  y-slide-in 0.25s ease-in,
  highlight 0.5s ease-in;
}

.animate-comment-counter-out {
  display: inline-block;
  animation: fade-out 0.5s ease-out,
  positive-rotate 0.5s ease-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes y-slide-in {
  0% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes positive-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes highlight {
  0% {
    color: inherit;
  }
  100% {
    color: $brand-color;
  }
}
