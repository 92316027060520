.comments {
}

.comments-pagination {
  margin-top: 2em;
  text-align: center;

  .current {
    color: $secondary-color;
  }

  a {
    color: black;
    text-decoration: none;
    background-color: transparent;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

.comments-empty {
}

.comment {
}

.comment + .comment {
  padding-top: 1em;
  margin-top: 1em;

  border-top: 1px dashed $secondary-color;
}

.comment-content {
  word-wrap: break-word;
}

.comment-info {
  font-style: italic;
  word-wrap: break-word;
}

.comment-info-country-flag {
}

.comment-info-proxy-vpn-tor-status {
  color: red;
  font-weight: bold;
  font-style: normal;
}
