.flash-messages-list {
  margin-top: 1em;
  margin-bottom: 1em;
}

.flash-messages-list-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  padding: 0.8em;
  border-radius: 0.4em;

  border: 1px solid $secondary-color;
  margin-bottom: 0.4em;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.flash-messages-list-item--success {
  .flash-messages-list-item-text {
    color: $success-color;
  }
}

.flash-messages-list-item--failure {
  .flash-messages-list-item-text {
    color: $failure-color;
  }
}

.flash-messages-list-item-text {
  flex: 1 1 auto;

  font-size: 1em;
  line-height: 2;
}

.flash-messages-list-item-close {
  @include reset-btn();
  flex: 0 0 auto;
  margin-left: 8px;

  display: block;
  width: 2em;
  height: 2em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  background-image: url("~images/close.svg");
  cursor: pointer;
  user-select: none;
}
