.admin {
  position: absolute;
  right: 0;
  top: 0;

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid $brand-color;
    transition: transform .4s ease-in-out;
  }

  img:hover {
    transform: rotate(720deg);
  }
}
