.donation {
  margin-bottom: 20px;
  padding: 20px;

  text-align: left;
  font-family: monospace;
  color: $hacker-color;

  background: black;
  border: 2px solid $brand-color;
}

.donation--highlight {
  border-width: 6px;
}

.donation-actions {
  text-align: center;
}

.donation-comment {
  margin-bottom: 10px;
  word-wrap: break-word;
}

// used for text animation
.donation-comment-symbol {
  display: inline-block;
  white-space: pre-wrap;
  transform: scale(1);
  transition: all 0.2s;
  transition-property: opacity, transform;

  &.-hidden {
    opacity: 0;
    transform: scale(30);
  }
}

.donation-info {
  text-align: right;
  margin-bottom: 10px;
}

.donation-reply {
  font-style: italic;
  margin-bottom: 10px;
  transform: translateY(0);
  transition: all 0.5s;
  transition-property: opacity, transform;

  &.-hidden {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.donation-url {
  text-align: right;

  span {
    color: $brand-color;
  }

  a {
    color: $hacker-color;
    font-family: monospace;
  }
}

.donation-url.flicker {
  span {
    color: #ffe6ff;
    animation: blink 1s linear infinite, flicker 1s linear infinite;
  }
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff,
      0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd,
      0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483,
      0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
