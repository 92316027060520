.modal {
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width:100%;
    height:100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.modalContent {
    outline: none;
}

.footer {
    position: fixed;
    bottom: 4px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    z-index: 10;
}

.footerContent {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 4px 6px;
}

.footerContent a {
    color: white;
}
