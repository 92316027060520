.warnet-header-logo {
  position: relative;
  height: 50px;
  background-color: #000;
  text-align: center;
  border-bottom: 4px solid $brand-color;

  img {
    height: 100%;
  }

  .admin {
    position: absolute;
    right: 10px;
    top: 10px;

    a {
      display: block;
      line-height: 0;
    }

    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 1px solid $brand-color;
      transition: transform .4s ease-in-out;
    }

    img:hover {
      transform: rotate(720deg);
    }
  }
}

.warnet-header-nav {
  text-align: center;
}

.warnet-header-nav .pure-menu-list .pure-menu-item {
  @media (max-width: 640px) {
    display: block;
  }
}

.warnet-header-nav .pure-menu-link {
  color: black;
  text-decoration: none;
  background-color: transparent;
}

.warnet-header-nav .pure-menu-link:hover,
.warnet-header-nav .pure-menu-link:focus {
  text-decoration: underline;
}
