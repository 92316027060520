.socials {
  margin-top: 1em;
  height: 20px;
  width: 100%;
  overflow: hidden;
}

.socials-content {
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
}

.socials-content-item {
  display: inline-block;
  animation: stream 30s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes stream {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
