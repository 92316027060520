@mixin reset-btn() {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-size: 1em;
  line-height: 1.8;
  box-shadow: none;

  &:focus {
    outline: none;
  }
}
