.user {
  max-width: 568px;

  margin: 0 auto;
  padding: 20px;

  color: $hacker-color;
  font-family: monospace;
  text-align: left;

  background: black;
  border: 2px solid $brand-color;

  a {
    color: $hacker-color;
  }
}
