/**
  Default warnet form
 */
.warnet-form {
  max-width: 568px;
  margin: 0 auto;
}

.warnet-form-messages-list {
  list-style: none;
  padding: 0;
}

.warnet-form-messages-list-item {
  color: $failure-color;
}

.warnet-form .pure-control-group {
  margin-bottom: 1em;
}

.warnet-form .label-required:after {
  content: "*";
  color: $required-color;
  margin-left: 0.2em;
}

.warnet-form .pure-form-message {
  color: $failure-color;
}

.warnet-form .pure-controls {
  text-align: center;
}

/**
  Default warnet editor
 */
.warnet-input-editor {
  padding: 0.5em 0.6em;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/**
  Post form and editor
 */
.post-form {}

.warnet-form--post-editor {
  max-width: 100%;
  margin: 0;
  padding: 0 38px;
}

.warnet-form--post-editor .pure-controls {
  text-align: left;
}

/**
  Comment form and editor
 */
.comment-form {}

.warnet-form--comment-editor {
  max-width: 100%;
  margin: 0;
}

.warnet-form--comment-editor .pure-controls {
  text-align: left;
}

.warnet-form--comment-editor .warnet-input-editor {
  padding: 0.5em 1.75em 0.5em 0.6em;

  // Override placeholder
  .editor-interface-placeholder {}

  // Override static toolbar
  .editor-static-toolbar-container {
    top: 0.5em;
    right: 0.5em;

    border-radius: 2px;

    height: 1.15em;
    width: 1.15em;

    &:hover {
      background-color: $brand-color;
    }
  }

  // Override floating toolbar
  .editor-floating-toolbar-container {
    button {
      color: $brand-color;
      border: 2px solid $brand-color;
      background-color: black;

      &.active {
        color: $hacker-color;
      }
    }

    button + button {
      border-left: none;
    }
  }

  // Override typeahead toolbar
  .editor-typeahead-toolbar-container {
    button {
      font-weight: bold;

      color: $brand-color;
      border: 2px solid $brand-color;
      background-color: black;
    }

    button + button {
      border-top: none;
    }
  }
}