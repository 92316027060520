body {
  background-color: #f5f5f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ui-sans-serif, system-ui, sans-serif;
}

.overflow-hidden {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.text-center {
  text-align: center;
}

.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  background-color: white;
  min-height: 100vh;
  box-sizing: border-box;

  main {
    padding-left: 15px;
    padding-right: 15px
  }
}

.actions {
  font-size: 0.8em;
  color: $brand-color;

  a {
    color: $brand-color;
  }
}
